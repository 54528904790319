import TournamentsPostModel from "../tournaments/models/tournaments-post.model";
import {mapState} from "vuex";
import imageInput from '@/mixins/image-input.js';

export default {
    name: "TournamentEdit",
    data() {
        return {
            item: {}
        }
    },
    methods: {
        approve() {
            let data = new TournamentsPostModel(this.item);

            let formData = new FormData();
            for (let key in data) {
                formData.append(key, data[key]);
            }

            this.$store.dispatch("tournaments/updateItem", {id: this.id, data: formData});
        }
    },
    created() {
        this.$store.dispatch("tournaments/getItemById", this.id).then((res) => {
            this.item = res;
        });
    },
    computed: {
        ...mapState({
            tournamentTypes: (state) => state.tournaments.tournamentTypes,
            categories: (state) => state.categories.items,
        }),
        id() {
            return this.$route.params.id;
        },
    },
    mixins: [imageInput],

}
